import ImageViewerObject from "./components/ImageViewerHandlers/ImageViewerObject";
import {ImageViewerCanvasObject} from "./components/ImageViewerHandlers/ImageViewerCanvasObject";
import {ImageViewerObjectFromCanvas} from "./Utilities";

export type StringWithAutocomplete<T> = T | (string & {});

export type Factory<T> = () => T;

export function memoized<T>(
    factory: Factory<T>,
    dirty: (current: T) => boolean = () => false
) {
    let result: undefined | T;
    return () => {
        if (result == null || dirty(result)) {
            result = factory();
        }
        return result;
    };
}

export function fork<A, B>(
    cond: boolean,
    ifTrue: A,
    otherwise: B,
): A | B {
    return cond ? ifTrue : otherwise;
}


export function isString(obj: any): obj is string {
    return typeof obj === "string";
}

export function ivObj(obj: ImageViewerObject | ImageViewerCanvasObject): ImageViewerObject {
    if (obj instanceof ImageViewerObject) {
        return obj;
    } else {
        return new ImageViewerObjectFromCanvas(obj);
    }
}

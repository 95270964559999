import {
  map,
  pairwise,
  switchMap,
  takeUntil,
  tap,
  startWith,
  take,
  filter,
  skip,
  observeOn,
  withLatestFrom
} from "rxjs/operators";
import {queueScheduler, timer, merge} from "rxjs";
import {viewerModes} from "../../../constants";
import {isGraphObject} from "../../../Utilities";
import {getTagClassValue} from "../ImageViewerObject";

const COLOR_RED = 'rgba(255,0,0,0.8)';

export default class ObjectSelectionHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
  }

  findTargetObject = (opt) => {
    const canvas = this.imageViewer.canvas;
    let result = canvas.findTarget(opt);

    if (result && result.fromAnotherDrawing) {
      const originId = result.origin.id;
      return canvas.getObjects().find(obj => obj.objectMetadata?.id === originId);
    }

    if (result && !result.isProperObject) {
      const curPoint = canvas.getPointer(opt.e);
      const matchingObjects = canvas.getObjects().filter(
          (obj) => obj.visible && obj.isProperObject && obj.containsPoint(curPoint, null, true)
      );
      result = matchingObjects[0];
    }
    return result;
  }

  handleSelectionUpdate = () => {
    const canvas = this.imageViewer.canvas;
    const objectToSelect = this.imageViewer.objectSelected$.value.target || this.imageViewer.anotherDrawingObjectSelected$.value.target;

    const activeObject = canvas.getActiveObject();
    if (objectToSelect === null && activeObject) {
      canvas.discardActiveObject();
      this.imageViewer.updateObjectVisibility(activeObject);
      this.imageViewer.objectsVisibilityChanged$.next(1);
    } else if (objectToSelect && objectToSelect !== activeObject) {
      canvas.setActiveObject(objectToSelect);
      this.imageViewer.updateObjectVisibility(objectToSelect);
      this.imageViewer.objectsVisibilityChanged$.next(1);
    }


  }

  registerEvents() {
    const canvas = this.imageViewer.canvas;

    // TODO: rename mode
    const MODES_WITH_SELECTION = [
      viewerModes.NORMAL,
    ];

    // feed events into objectSelected$
    this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => MODES_WITH_SELECTION.includes(mode)), map(([opt, _]) => opt),

        tap(opt => {
          if (opt.transform?.corner) return;
          const selectedObject = this.findTargetObject(opt);
          const curPoint = canvas.getPointer(opt.e);

          if (selectedObject) {
            this.imageViewer.objectMouseClicked$.next({target: selectedObject, source: 'canvas', e: opt.e});

            const matchingObjects = canvas.getObjects().filter(
                (obj) => obj.visible && (obj.isProperObject || obj.fromAnotherDrawing) && obj.containsPoint(curPoint, null, true)
            );

            const currentObject = canvas.getActiveObject();
            let objectToSelect = null;

            const currentObjectIndex = matchingObjects.indexOf(currentObject);
            if (currentObjectIndex !== -1) {
              objectToSelect = null;
              // objectToSelect = matchingObjects[(currentObjectIndex + 1) % matchingObjects.length];
            } else {
              const getArea = (rect) => {
                return rect.width * rect.height;
              }
              objectToSelect = matchingObjects.sort((x, y) => getArea(x) - getArea(y))[0]
            }

            if (objectToSelect) {
              canvas.setActiveObject(objectToSelect);

              if (objectToSelect.fromAnotherDrawing) {
                this.imageViewer.anotherDrawingObjectSelected$.next({target: objectToSelect, source: 'canvas'});
                // workaround for objectSelected$ to be taken into account in takeUntil below
                timer(0).subscribe(() => this.imageViewer.objectSelected$.next({target: null, source: 'canvas'}));
              } else {


                this.imageViewer.anotherDrawingObjectSelected$.next({target: null, source: 'canvas'});
                // workaround for objectSelected$ to be taken into account in takeUntil below
                timer(0).subscribe(() => this.imageViewer.objectSelected$.next({target: objectToSelect, source: 'canvas', mouseEvent: opt.e}));
              }
            }
          }
          this.imageViewer.renderAll$.next(1);
        }),
        switchMap(opt2 => this.imageViewer.mouseUp$.pipe(
            // takeUntil(this.imageViewer.mouseMove$),
            takeUntil(merge(
                this.imageViewer.mouseMove$,
                this.imageViewer.objectSelected$.pipe(skip(1)), // skip 1 as objectSelected$ is a BehaviorSubject
            )),
            //(in case of overlapping objects)
            tap(opt => {
              if (opt2.transform?.corner) return;
              const selectedObject = this.findTargetObject(opt);
              const curPoint = canvas.getPointer(opt.e);

              if (selectedObject) {
                const matchingObjects = canvas.getObjects().filter(
                    (obj) => obj.visible && (obj.isProperObject || obj.fromAnotherDrawing) && obj.containsPoint(curPoint, null, true)
                );

                const currentObject = canvas.getActiveObject();

                const currentObjectIndex = matchingObjects.indexOf(currentObject);
                if (currentObjectIndex !== -1) {
                  const objectToSelect = matchingObjects[(currentObjectIndex + 1) % matchingObjects.length];
                  canvas.setActiveObject(objectToSelect);

                  if (objectToSelect.fromAnotherDrawing) {
                    this.imageViewer.objectSelected$.next({target: null, source: 'canvas'});
                    this.imageViewer.anotherDrawingObjectSelected$.next({target: objectToSelect, source: 'canvas'});
                  } else {
                    this.imageViewer.objectSelected$.next({target: objectToSelect, source: 'canvas'});
                    this.imageViewer.anotherDrawingObjectSelected$.next({target: null, source: 'canvas'});
                  }

                }
              }
              this.imageViewer.renderAll$.next(1);
            }),
        )),
    ).subscribe());

    // highlight the currently selected object with red color
    this.imageViewer.subscriptions.push(this.imageViewer.objectSelected$.pipe(
        observeOn(queueScheduler),
        pairwise(),
        tap(pair => {
          const prevObject = pair[0].target;
          const curObject = pair[1].target;
          if (prevObject) {
            if (isGraphObject(prevObject)) {
              prevObject.wrappingData.helper.setSelected(false);
            } else {
              prevObject.stroke = this.imageViewer.getColor(getTagClassValue(prevObject));
            }
            this.imageViewer.updateObjectVisibility(prevObject);
            this.imageViewer.objectsVisibilityChanged$.next(1);
          }

          if (curObject) {
            if (isGraphObject(curObject)) {
              curObject.wrappingData.helper.setSelected(true);
            } else {
              curObject.stroke = COLOR_RED;
            }
            this.imageViewer.canvas.setActiveObject(curObject);
            this.imageViewer.canvas.requestRenderAll();
            this.imageViewer.renderAll$.next(1);
          }

          this.imageViewer.updateObjectsVisibility();
          this.imageViewer.canvas.requestRenderAll();
        }),
    ).subscribe());

    // synchronize the current object with the treeview
    this.imageViewer.subscriptions.push(this.imageViewer.objectSelected$.pipe(
        tap(opt => {
          const target = opt.target;
          if (target === null) {
            this.imageViewer.setState({selectedObject: null});
            return;
          }

          this.imageViewer.setState({selectedObject: target}, () => {
            this.imageViewer.updateObjectVisibility(this.imageViewer.state.selectedObject);
            this.imageViewer.objectsVisibilityChanged$.next(1);

            this.imageViewer.onNextFrame(() => {
              let newExpandedKeys = this.imageViewer.state.expandedKeys;
              const objectType = getTagClassValue(target);
              let msecsToWait = 0;
              let needToScroll = false;

              if (opt.source === 'canvas') {
                needToScroll = true;
              }
              if (!newExpandedKeys.includes(objectType)) {
                newExpandedKeys = [...newExpandedKeys, objectType];
              }
              if (!newExpandedKeys.includes('root-node')) {
                newExpandedKeys = [...newExpandedKeys, 'root-node'];

              }
              // msecsToWait = 500;

              this.imageViewer.setState({
                selectedKeys: [`${target.objectMetadata.id}`],
                expandedKeys: newExpandedKeys,
              }, () => {
                if (needToScroll && this.imageViewer.state.viewMode === "tree-view") {
                  // change treeview scroll position only if the object was selected on canvas
                  const attempts$ = timer(100, 300).pipe(take(8));

                  attempts$.pipe(
                      takeUntil(
                          attempts$.pipe(
                              filter(() => {
                                const lng = document.getElementsByClassName('ant-tree-treenode-selected').length;
                                return lng > 0;
                              }),
                              skip(2),
                          )
                      ),
                      startWith(-1),
                  ).subscribe(val => {
                    // this line is crucial for some reason
                    this.imageViewer.setState({selectedKeys: [`${target.objectMetadata.id}`]});
                    if (this.imageViewer.treeRef.current) this.imageViewer.treeRef.current.scrollTo({key: target.objectMetadata.id.toString()});
                  })
                }
              });
            });
          });
        }),
    ).subscribe());


    // adjust selection in accordance with objectSelected$
    canvas.on('selection:created', (opt) => this.handleSelectionUpdate());
    canvas.on('selection:updated', (opt) => this.handleSelectionUpdate());
    canvas.on('selection:cleared', (opt) => this.handleSelectionUpdate());

    this.imageViewer.subscriptions.push(this.imageViewer.objectSelected$.subscribe(() => this.handleSelectionUpdate()));
    // this.imageViewer.subscriptions.push(this.imageViewer.anotherDrawingObjectSelected$.subscribe(() => this.handleSelectionUpdate()));
  }
}

import React, {useState} from "react";
import {Button, Card, Divider, Dropdown, Form, Input, List, Menu, Popconfirm, Popover, Row, Space, Table} from "antd";
import {EditableTextField} from "../../EditableFields/EditableTextField";
import {EditableSelectField} from "../../EditableFields/EditableSelectField";
import {DeleteOutlined, EditOutlined, EllipsisOutlined} from "@ant-design/icons";
import {openInNewTab} from "../../../Utilities";
import {EditableAutoCompleteField} from "../../EditableFields/EditableAutoCompleteField";
import {AddNewCommentOnObject} from "../Comments/Components/AddNewCommentOnObject";
import {EntityLinksDropdown} from "../../Misc/EntityLinks/EntityLinks";
import {
    BaseNodeLinks,
    DMLink,
    EHLink,
    IHLink,
    KHSearchLink,
    WBLink
} from "../../Misc/EntityLinks/source/EntityLinksSource";
import {CustomAttributesTable} from "./CustomAttributes/CustomAttributesTable";
import {CustomAttributesContextProvider} from "./CustomAttributes/CustomAttributesContext";
import {ObjectDataContextProvider} from "./ObjectDataContext";


export class ObjectData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredRowIndex: null,
            newAttributePopoverVisible: false,
            newLinkPopoverVisible: false,
            newAttribute: {
                name: "",
                value: "",
            }
        };

        this.newAttributeNameRef = React.createRef();
    }

    // componentDidUpdate(prevProps, prevState) {
    //   if (prevProps.selectedObject !== this.props.selectedObject) {
    //     // debugger;
    //   }
    // }

    getAttribute = (name) => {
        if (!this.props.selectedObject) return null;
        const mdata = this.props.selectedObject.objectMetadata;
        if (name === "label")
            return mdata.label;
        else if (name === "text")
            return mdata.text;
        else if (name === "description")
            return mdata.metadata.description;
        else {
            let attrObj = mdata.metadata.attributes.find(attr => attr.key === name);

            if (!attrObj) {
                return "";
            }

            return attrObj.value;
        }
    };

    // creates new attribute if attribute 'name' doesn't exist
    setAttribute = (name, value) => {
        const mdata = this.props.selectedObject.objectMetadata;
        if (name === "label")
            mdata.label = value;
        else if (name === "text")
            mdata.text = value;
        else if (name === "description") {
            mdata.metadata.description = value;
        } else {
            const attrObj = mdata.metadata.attributes.find(attr => attr.key === name);
            if (attrObj) {
                attrObj.value = value;
            } else {
                mdata.metadata.attributes.push({
                    key: name,
                    value: value
                });
            }

        }
        this.props.imageViewer.objectsChanged$.next(1);
        this.forceUpdate();
    };

    setLinks = (links) => {
        const mdata = this.props.selectedObject.objectMetadata;

        mdata.metadata.links = links;

        this.props.imageViewer.objectsChanged$.next(1);
        this.forceUpdate();
    };

    handleDeleteAttribute = (attributeName) => {
        const mdata = this.props.selectedObject.objectMetadata;
        mdata.metadata.attributes = mdata.metadata.attributes.filter(attr => attr.key !== attributeName);
        this.props.imageViewer.objectsChanged$.next(1);
        this.forceUpdate();
    };

    renderReadOnlyAttributes = () => {
        const formLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18},
        };

        const tableCols = [
            {
                title: "attribute",
                dataIndex: "key",
                width: "30%",
                render: (text, record, index) => {
                    return {
                        props: {
                            style: {background: "WhiteSmoke"}
                        },
                        children: (
                            <div style={{width: "auto", height: "100%", margin: "-8px", padding: "8px"}}>
                                {text}
                            </div>
                        )
                    };
                }
            },
            {
                title: "value",
                dataIndex: "value",
            },
        ];

        return (<React.Fragment>
            <span style={{color: "var(--color-yellow)"}}>Object from another drawing</span><br/>
            <Table
                id="object-attributes"
                dataSource={this.props.readOnlyAttributes}
                columns={tableCols}
                size="small"
                pagination={false}
                showHeader={false}
                bordered={true}/>
        </React.Fragment>);
    };

    renderAssignedToPageFieldNotification = () => {
        const assignedField = this.props.assignedPageField;

        if (assignedField == null) return null;

        return (
            <>
          <span style={{color: "var(--color-turquoise)"}}>
            {`Assigned to page field '${assignedField.key}'`}
          </span><br/>
            </>
        );
    };

    render() {
        let tableData = [
            {
                attribute: "Class",
                valueKey: "label",
                fieldType: "select",
                fieldParams: {
                    options: this.props.labelsList,
                }
            },
            {
                attribute: "Text",
                valueKey: "text",
                fieldType: "text",
            },
            {
                attribute: "Description",
                valueKey: "description",
                fieldType: "text",
                // value: this.getAttribute('label'),
            }
        ];

        let linksToShow = [];
        const selectedObject = this.props.selectedObject;

        const allowedSystemAttributes = this.props.systemAttributes.filter(systemAttr =>
            selectedObject && systemAttr.object_label === this.props.selectedObject.objectMetadata.label
        );
        const allowedSystemAttributesKeys = allowedSystemAttributes.map(systemAttr => systemAttr.attribute_name);

        tableData = [
            ...tableData,
            ...allowedSystemAttributes.map(systemAttr => ({
                attribute: systemAttr.attribute_name,
                valueKey: systemAttr.attribute_name,
                fieldType: "select",
                fieldParams: {
                    options: systemAttr.values_list
                }
            }))
        ];

        if (this.props.selectedObject) {
            tableData = [
                ...tableData,
                ...this.props.selectedObject.objectMetadata.metadata.attributes
                    .filter(attr => !allowedSystemAttributesKeys.includes(attr.key))
                    .map(attr => ({
                        attribute: attr.key,
                        valueKey: attr.key,
                        fieldType: "text",
                    })),
            ];

            const objectLinks = this.props.selectedObject.objectMetadata.metadata.links;
            if (objectLinks && objectLinks.length > 0) {
                linksToShow = objectLinks;
            }
        }
        const isEditable = this.props.imageViewer.props.editable && this.props.selectedObject;
        const tableCols = [
            {
                title: "attribute",
                dataIndex: "attribute",
                width: "30%",
                render: (text, record, index) => {
                    const deleteIconShown = isEditable && index === this.state.hoveredColIndex
                        && !["label", "text", "description"].concat(allowedSystemAttributesKeys).includes(record.valueKey);
                    return {
                        props: {
                            style: {background: "WhiteSmoke"}
                        },
                        children: (
                            <div style={{width: "auto", height: "100%", margin: "-8px", padding: "8px"}}
                                 onMouseEnter={() => this.setState({hoveredColIndex: index})}
                                 onMouseLeave={() => this.setState({hoveredColIndex: -1})}>
                                {text}
                                <Popconfirm
                                    placement="topLeft"
                                    title={`Are you sure to delete attribute '${text}'?`}
                                    onConfirm={() => this.handleDeleteAttribute(text)}
                                    okText="Yes" cancelText="No"
                                >
                                    <a style={{visibility: deleteIconShown ? "initial" : "hidden"}}><DeleteOutlined/></a>
                                </Popconfirm>
                            </div>
                        )
                    };
                }
            },
            {
                title: "value",
                dataIndex: "valueKey",
                render: (valueKey, record, index) => {
                    if (record.fieldType === "text") {
                        return <EditableTextField
                            value={this.getAttribute(valueKey)}
                            editIconShown={isEditable && index === this.state.hoveredRowIndex}
                            onUpdate={(newText) => this.setAttribute(valueKey, newText)}
                        />;
                    } else if (record.fieldType === "select") {
                        if (record.attribute === "Class") {
                            return <EditableSelectField
                                value={this.getAttribute(valueKey)}
                                optionsList={record.fieldParams.options}
                                editIconShown={isEditable && index === this.state.hoveredRowIndex}
                                onUpdate={(newText) => this.setAttribute(valueKey, newText)}
                            />;
                        }

                        return <EditableAutoCompleteField
                            value={this.getAttribute(valueKey)}
                            optionsList={record.fieldParams.options}
                            editIconShown={isEditable && index === this.state.hoveredRowIndex}
                            onUpdate={(newText) => this.setAttribute(valueKey, newText)}
                        />;
                    }
                }
            },
        ];
        const formLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18},
        };
        const newAttributePopoverContent = (
            <div style={{width: "188px"}}>
                <Form
                    {...formLayout}
                >
                    <Form.Item label="name" style={{marginBottom: "4px"}}>
                        <Input id="new-attribute-name" size="small"
                               ref={this.newAttributeNameRef}
                               value={this.state.newAttribute.name}
                               onChange={(e) => this.setState({
                                   newAttribute: {
                                       ...this.state.newAttribute,
                                       name: e.target.value
                                   }
                               })}
                        ></Input>
                    </Form.Item>
                    <Form.Item label="value" style={{marginBottom: "12px"}}>
                        <Input id="new-attribute-value" size="small" value={this.state.newAttribute.value}
                               onChange={(e) => this.setState({
                                   newAttribute: {
                                       ...this.state.newAttribute,
                                       value: e.target.value
                                   }
                               })}
                        ></Input>
                    </Form.Item>
                    <Row>
                        <Space>
                            <Button size="small" style={{width: 90}}
                                    onClick={() => this.setState({newAttributePopoverVisible: false})}>Cancel</Button>
                            <Button
                                id="add-new-attribute-button"
                                type="primary" size="small" style={{width: 90}}
                                onClick={() => {
                                    this.setAttribute(this.state.newAttribute.name, this.state.newAttribute.value);
                                    this.setState({newAttributePopoverVisible: false});
                                }}
                            >Add</Button>
                        </Space>
                    </Row>
                </Form>
            </div>
        );

        const emptyLink = {name: "", target: "", type: "default"};

        const extraOptionsMenuItems = [];

        if (isEditable) {
            extraOptionsMenuItems.push(
                <Menu.Item
                    key={"add-new-link"}
                    id="add-new-link-option"
                >
                    <PopoverLinkEditor initialValue={emptyLink}
                                       visible={this.state.newLinkPopoverVisible}
                                       onVisibleChange={visible => this.setState({newLinkPopoverVisible: visible})}
                                       title="Add new link"
                                       forbiddenNames={linksToShow.map(link => link.name)}
                                       onOk={link => {
                                           this.setState({newLinkPopoverVisible: false});

                                           const updatedLinks = [...linksToShow, link];
                                           this.setLinks(updatedLinks);
                                       }}
                                       onCancel={e => this.setState({newLinkPopoverVisible: false})}>
                        <a
                            onClick={e => this.setState({newLinkPopoverVisible: true})}
                        >
                            Add new link
                        </a>
                    </PopoverLinkEditor>
                </Menu.Item>
            );
        }

        if (this.props.selectedObject && this.props.commentsAllowed) {
            extraOptionsMenuItems.push(
                <Menu.Item
                    key={"add-new-comment"}
                    id="add-new-comment"
                >
                    <AddNewCommentOnObject
                        annotationObject={this.props.selectedObject}
                        onAddNewComment={comment => this.props.imageViewer.newCommentRequested$.next(comment)}
                    >
                        {props => (
                            <a onClick={props.onClick}>
                                Add new comment
                            </a>
                        )}
                    </AddNewCommentOnObject>
                </Menu.Item>
            );
        }

        const extraOptionsDropdownOverlay = (
            <Menu>
                {extraOptionsMenuItems}
            </Menu>
        );

        return (
            <ObjectDataContextProvider
                isEditable={isEditable}
                selectedObject={this.props.selectedObject}
            >
                <CustomAttributesContextProvider
                    selectedObject={this.props.selectedObject}
                    source={this.props.customObjectAttributesSource}
                >
                    <Card id="object-data"
                          title={(
                              <Row style={{justifyContent: "space-between"}}>
                                  <div>
                                      Object data
                                      {this.props.selectedObject?.objectMetadata && (
                                          <EntityLinksDropdown
                                              source={new BaseNodeLinks([
                                                  new DMLink(this.props.selectedObject.objectMetadata.text),
                                                  new IHLink(this.props.selectedObject.objectMetadata.text),
                                                  new EHLink(this.props.selectedObject.objectMetadata.text),
                                                  new WBLink(this.props.selectedObject.objectMetadata.text),
                                                  new KHSearchLink(this.props.selectedObject.objectMetadata.text)
                                              ])}
                                          />
                                      )}
                                  </div>
                                  <Space>
                                      {this.props.extra}
                                      {isEditable && this.props.customObjectAttributesSource == null && (
                                          <Popover
                                              content={newAttributePopoverContent}
                                              title="Add new attribute"
                                              trigger="click"
                                              visible={this.state.newAttributePopoverVisible}
                                              onVisibleChange={visible => {
                                                  this.setState({
                                                      newAttributePopoverVisible: visible,
                                                      newAttribute: {
                                                          name: "",
                                                          value: "",
                                                      }
                                                  });
                                                  if (visible) {

                                                      setTimeout(() => this.newAttributeNameRef.current.select(), 100);
                                                  }
                                              }}
                                          >
                                              <Button size="small">+</Button>

                                          </Popover>
                                      )
                                      }
                                      {extraOptionsMenuItems.length > 0 && (
                                          <Dropdown
                                              trigger={["click"]}
                                              overlay={extraOptionsDropdownOverlay}
                                              size="small"
                                          >
                                              <Button id="extra-object-data-options-button" size="small"
                                                      icon={<EllipsisOutlined/>}/>
                                          </Dropdown>
                                      )}
                                  </Space>

                              </Row>

                          )
                          }
                          size="small" style={{height: "auto", minHeight: "100%"}}>
                        {this.props.header}
                        {this.props.customObjectAttributesSource == null && (this.props.selectedObject || !this.props.readOnlyAttributes) &&
                            <Table
                                id="object-attributes"
                                dataSource={tableData}
                                columns={tableCols}
                                size="small"
                                pagination={false}
                                showHeader={false}
                                bordered={true}
                                rowKey={(record, index) => {
                                    const label = this.props.selectedObject?.objectMetadata?.label;
                                    if (label == null) {
                                        return index;
                                    } else {
                                        return label + record.valueKey;
                                    }
                                }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onMouseEnter: event => this.setState({hoveredRowIndex: rowIndex}),
                                        onMouseLeave: event => this.setState({hoveredRowIndex: null}),
                                    };
                                }}
                            />}
                        {this.props.customObjectAttributesSource == null && !this.props.selectedObject && this.props.readOnlyAttributes && this.renderReadOnlyAttributes()}
                        {this.props.customObjectAttributesSource != null && (
                            <CustomAttributesTable
                                selectedObject={this.props.selectedObject}
                                source={this.props.imageViewer.state.customObjectAttributesSource}
                            />
                        )}

                        {linksToShow.length > 0 && (
                            <div>
                                <Divider orientation="left">Links</Divider>
                                <LinksExplorer linksToShow={linksToShow} isEditable={isEditable}
                                               onLinksChanged={this.setLinks}/>
                            </div>
                        )}
                    </Card>
                </CustomAttributesContextProvider>
            </ObjectDataContextProvider>
        );
    }
}

function LinksExplorer({linksToShow, isEditable, onLinksChanged}) {
    const [editedLink, setEditedLink] = useState(null);
    const [hoveredLinkItem, setHoveredLinkItem] = useState(null);

    const updateLink = (oldLink, newLink) => {
        const updatedLinks = linksToShow.map(link => link === oldLink ? newLink : link);

        onLinksChanged(updatedLinks);
    };

    const removeLinkByIdx = (removeIdx) => {
        const updatedLinks = linksToShow.filter((_, idx) => idx !== removeIdx);

        onLinksChanged(updatedLinks);
    };

    const hideEditor = () => {
        setEditedLink(null);
    };

    const listItemActions = (link, idx) => {
        if (!isEditable) return [];

        const isLinkEdited = link === editedLink;
        const isLinkHovered = hoveredLinkItem === link;

        return [
            (
                <PopoverLinkEditor initialValue={link}
                                   visible={isLinkEdited}
                                   onVisibleChange={visible => {
                                       if (!visible) {
                                           hideEditor();
                                       }
                                   }}
                                   title="Edit link"
                                   forbiddenNames={linksToShow.map(link => link.name).filter(forbiddenName => editedLink?.name !== forbiddenName)}
                                   onOk={link => {
                                       hideEditor();
                                       updateLink(editedLink, link);
                                   }}
                                   onCancel={hideEditor}
                >
                    <div style={{width: "16px", height: "16px"}}>
                        {isLinkHovered &&
                            <a id={"edit-link-button"} onClick={() => {
                                setEditedLink(link);
                            }}>
                                <EditOutlined/>
                            </a>}
                    </div>
                </PopoverLinkEditor>
            ),
            <Popconfirm
                title={`Are you sure to delete link '${link.name}'?`}
                onConfirm={() => removeLinkByIdx(idx)}
                okText="Yes" cancelText="No"
            >
                <div style={{width: "16px", height: "16px"}}>
                    {isLinkHovered && <a id={"remove-link-button"}><DeleteOutlined/></a>}
                </div>
            </Popconfirm>
        ];
    };

    return (
        <>
            <List size="small">
                {
                    linksToShow.map((link, idx) => (
                        <List.Item id={`link-item_${idx}`} actions={listItemActions(link, idx)}
                                   onMouseEnter={e => setHoveredLinkItem(link)}
                                   onMouseLeave={e => setHoveredLinkItem(null)}
                        >
                            <a onClick={() => openInNewTab(link.target)}>{link.name}</a>
                        </List.Item>
                    ))
                }
            </List>
        </>
    );
}

function PopoverLinkEditor({title, visible, onVisibleChange, initialValue, forbiddenNames, onOk, onCancel, children}) {
    const formId = "link-editor-form";

    const content = (
        <div style={{width: "376px"}}>
            <LinkEditorForm id={formId} key="alive" initialValue={initialValue} forbiddenNames={forbiddenNames}
                            onFinish={onOk}/>
            <Row
                style={{justifyContent: "space-between"}}
            >
                <Button size="small" onClick={onCancel} style={{width: 90}}>Cancel</Button>
                <Button
                    form={formId}
                    key="submit" htmlType="submit"
                    type="primary" size="small" style={{width: 90}}
                >OK</Button>
            </Row>
        </div>
    );

    return (
        <Popover
            destroyTooltipOnHide={true}
            visible={visible}
            trigger={"click"}
            title={title}
            onVisibleChange={onVisibleChange}
            content={content}
        >
            {children}
        </Popover>
    );
}

function LinkEditorForm({id, initialValue, forbiddenNames, onFinish}) {
    const formLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 21},
    };

    const nameRules = [
        {
            required: true
        },
        {
            validator: async (_, target) => {
                if (!forbiddenNames.includes(target)) return Promise.resolve();

                return Promise.reject(new Error("Link names must be unique!"));
            }
        }
    ];

    return (
        <Form id={id}
              hideRequiredMark={true}
              onFinish={(value) => onFinish({...value, type: initialValue.type})}
              initialValues={initialValue}
              {...formLayout}
        >
            <Form.Item label="name"
                       name="name"
                       style={{marginBottom: "4px"}}
                       rules={nameRules}>
                <Input id="link-name-input" size="small"/>
            </Form.Item>
            <Form.Item label="target" name="target" style={{marginBottom: "12px"}} rules={[{required: true}]}>
                <Input id="link-target-input" size="small"/>
            </Form.Item>
        </Form>
    );
}
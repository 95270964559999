import React, {MouseEventHandler, useContext, useEffect, useMemo, useState} from "react";
import {Checkbox, Dropdown, message, Spin} from "antd";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import HierarchyNode from "../../ImageViewerHandlers/Hierarchy/HierarchyNode";
import {ViewHierarchy} from "./ViewHierarchy";
import {NodeRefDescription, SelectReferenceToGoModal} from "./SelectReferenceToGoModal";
import {AsyncDataSource} from "../../Misc/Table/AsyncDataSourceGeneric";
import Menu from "antd/lib/menu";
import {truncate} from "../../../Utilities";
import {Child, childIsNode} from "./Types/ParentChildSelectorState";
import {HierarchyTreeContext} from "./HierarchyTreeContext";


export function NodeSubtreeTitle(
    {viewHierarchy, hierarchy, node, nodeColor, onClick}: {
        viewHierarchy: ViewHierarchy;
        node: HierarchyNode,
        hierarchy: Hierarchy,
        onClick: MouseEventHandler,
        nodeColor: any
    }
) {
    const [switchDrawingModalVisible, setSwitchDrawingModalVisible] = useState(false);
    const [showSpin, setShowSpin] = useState(false);
    const [isInBuffer, setIsInBuffer] = useState(false);

    const treeContext = useContext(HierarchyTreeContext);
    const parentChildSelectorState = treeContext.parentChildState;
    const cutPasteState = treeContext.cutPasteState;

    const nodeRefsDataSource = useMemo<AsyncDataSource<NodeRefDescription>>(
        () => node.getRefsWithExtraDataSource(true),
        [node]
    );

    const handleDoubleClick = () => {
        if (node.isVirtual() || node.hasOnPageReference()) return;

        setShowSpin(true);

        nodeRefsDataSource.values(
            1, 1
        ).then(refs => {
            if (refs.length === 0) {
                message.warning("No reference found");
            } else if (refs.length === 1) {
                refs[0].reference.open();
            } else {
                setSwitchDrawingModalVisible(true);
            }
        }).finally(() => {
            setShowSpin(false);
        });
    };

    useEffect(() => {
        const sub = viewHierarchy.multipleNodesListChanged$.subscribe(nodes => {
            setIsInBuffer(nodes.some(x => x.isRelatedToNode(node)));
        });
        return () => sub.unsubscribe();
    }, [viewHierarchy, node]);

    const shouldShowMoveAction = (child: Child) => {
        if (childIsNode(child)) {
            return !node.equals(child.target);
        } else {
            return true;
        }
    };

    const isCut = useMemo(
        () => node.isDescendantOf(cutPasteState.node),
        [node, cutPasteState]
    );

    return (
        <>
            <SelectReferenceToGoModal
                key={node.id}
                dataSource={nodeRefsDataSource}
                visible={switchDrawingModalVisible}
                onVisibleChange={val => setSwitchDrawingModalVisible(val)}
            />
            <Dropdown
                trigger={["contextMenu"]}
                overlay={() => {
                    const isLeaf = node.isLeaf();
                    const isExpanded = viewHierarchy.nodeIsExpanded(node);
                    return (
                        <Menu style={{minWidth: "120px"}}>
                            <Menu.Item disabled>
                                {truncate(node.text, 20)}
                            </Menu.Item>
                            <Menu.Divider/>
                            <Menu.Item
                                disabled={node.isRoot()}
                                onClick={() => {
                                    viewHierarchy.cut(node);
                                }}
                            >
                                Cut
                            </Menu.Item>
                            <Menu.Item
                                disabled={cutPasteState.node == null || isCut}
                                onClick={() => {
                                    viewHierarchy.pasteTo(node);
                                }}
                            >
                                Paste
                            </Menu.Item>
                            {!isLeaf && (
                                <Menu.Item
                                    onClick={() => {
                                        if (isExpanded) {
                                            viewHierarchy.collapseSubtree(node);
                                        } else {
                                            viewHierarchy.expandSubtree(node);
                                        }
                                    }}
                                >
                                    {isExpanded ? "Collapse subtree" : "Expand subtree"}
                                </Menu.Item>
                            )}
                            {/*{!node.isRoot() && (*/}
                            {/*    <Menu.Item key={"set-as-child"}>*/}
                            {/*        <a onClick={() => {*/}
                            {/*            viewHierarchy.handleSetChild({*/}
                            {/*                target: node,*/}
                            {/*                targetType: "node",*/}
                            {/*            });*/}
                            {/*        }}>Set as child</a>*/}
                            {/*    </Menu.Item>*/}
                            {/*)}*/}
                            {/*{parentChildSelectorState.child != null && shouldShowMoveAction(parentChildSelectorState.child) && (*/}
                            {/*    <Menu.Item key="move-child-to-parent">*/}
                            {/*        <a onClick={() => {*/}
                            {/*            viewHierarchy.moveChildToParent(node);*/}
                            {/*        }}>*/}
                            {/*            Move {truncate(textOf(parentChildSelectorState.child), 20)} here*/}
                            {/*        </a>*/}
                            {/*    </Menu.Item>*/}
                            {/*)}*/}
                        </Menu>
                    );
                }}
            >
                <div
                    onClick={onClick} onDoubleClick={handleDoubleClick}
                >
                    {isInBuffer && (
                        <Checkbox
                            checked={isInBuffer}
                            style={{marginRight: "8px"}}
                        />
                    )}
                    <span style={{backgroundColor: nodeColor}} className="medium-circle"/>
                    {node.text}
                    {showSpin && (
                        <Spin
                            // @ts-ignore
                            id="double-click-processing-spin"
                            spinning={true}
                        />
                    )}
                    {isCut && (
                        <span style={{
                            marginLeft: "8px",
                            backgroundColor: "#F2F4F5",
                            outline: "1px solid",
                            outlineColor: "#DBDBDB"
                        }}>
                            cut
                        </span>
                    )}
                </div>
            </Dropdown>
        </>
    );
}

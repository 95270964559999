import {CustomObjectAttribute, CustomObjectAttributes, CustomObjectAttributesSource} from "../CustomAttributesSource";
import ImageViewer from "../../../ImageViewer";
import {ImageViewerCanvasObject} from "../../../../ImageViewerHandlers/ImageViewerCanvasObject";
import AllResultsService from "../../../../../services/AllResultsService";
import HierarchiesService, {HierarchySystemAttributes, NodeModel} from "../../../../../services/HierarchiesService";
import {Factory} from "../../../../../UtilitiesTs";

export class NodeAttributesSource implements CustomObjectAttributesSource {
    private readonly _iv: ImageViewer;
    private readonly _hierarchyId: string;
    private readonly _systemAttributes: Factory<Promise<HierarchySystemAttributes[]>>;

    constructor(iv: ImageViewer, hierarchyId: string, systemAttributes: Factory<Promise<HierarchySystemAttributes[]>>) {
        this._iv = iv;
        this._hierarchyId = hierarchyId;
        this._systemAttributes = systemAttributes;
    }

    async load(obj: ImageViewerCanvasObject): Promise<CustomObjectAttributes> {
        const [node, attributes] = await Promise.all([
            AllResultsService.getNodeByTagReference(
                this._hierarchyId,
                {
                    result_reference: this._iv.toResultReference(),
                    tag_id: obj.objectMetadata.id.toString(),
                    tag_number: obj.objectMetadata.text
                }
            ),
            this._systemAttributes()
        ]);
        return new NodeAttributes(this._iv, node.data, attributes);
    }
}

class NodeAttributes implements CustomObjectAttributes {
    private readonly _iv: ImageViewer;
    private readonly _node: NodeModel;
    private readonly _systemAttributes: HierarchySystemAttributes[];
    public attributes: CustomObjectAttribute[];

    constructor(iv: ImageViewer, node: NodeModel, systemAttributes: HierarchySystemAttributes[]) {
        this._iv = iv;
        this._node = node;
        this._systemAttributes = systemAttributes;
        this.attributes = this.convertToAttributesList(node);
    }

    createAttribute(key: string, value: string): Promise<CustomObjectAttributes> {
        if (["__label__", "__text__"].includes(key)) {
            throw new Error("Incorrect key!");
        }
        return this.updateAttributeValue(key, value);
    }

    async updateAttributeValue(key: string, value: string): Promise<CustomObjectAttributes> {
        if (["__label__", "__text__"].includes(key)) {
            throw new Error("Key is readonly!");
        }

        const attributes = this._node.attributes;
        const attrIdx = attributes.findIndex(x => x.key === key);
        if (attrIdx !== -1) {
            attributes[attrIdx].value = value;
        } else {
            attributes.push({
                key: key,
                value: value
            });
        }

        const newNode = await HierarchiesService.updateHierarchyNodeRaw(this._node.hierarchy_id, this._node);
        this.notifyHierarchyChanged();
        return new NodeAttributes(this._iv, newNode.data, this._systemAttributes);
    }

    async deleteAttribute(key: string): Promise<CustomObjectAttributes> {
        if (["__label__", "__text__"].includes(key)) {
            throw new Error("Key is readonly!");
        }

        this._node.attributes = this._node.attributes.filter(x => x.key !== key);

        const newNode = await HierarchiesService.updateHierarchyNodeRaw(this._node.hierarchy_id, this._node);
        this.notifyHierarchyChanged();
        return new NodeAttributes(this._iv, newNode.data, this._systemAttributes);
    }

    private convertToAttributesList(node: NodeModel): CustomObjectAttribute[] {
        const res: CustomObjectAttribute[] = [];
        res.push({
            alias: "Class",
            key: "__label__",
            value: node.label,
            isReadonly: true,
            canBeDeleted: false,
            type: "text",
        });
        res.push({
            alias: "Text",
            key: "__text__",
            value: node.text,
            isReadonly: true,
            canBeDeleted: false,
            type: "text",
        });

        const systemAttributes = this._systemAttributes.filter(
            x => x.label === node.label
        );
        const systemAttrKeys = systemAttributes.map(x => x.name);
        for (let systemAttribute of systemAttributes) {
            res.push({
                key: systemAttribute.name,
                value: this._node.attributes.find(x => x.key === systemAttribute.name)?.value ?? "",
                isReadonly: false,
                canBeDeleted: false,
                type: "autocomplete",
                options: systemAttribute.options
            });
        }

        const restAttributes = node.attributes.filter(x => !systemAttrKeys.includes(x.key));
        for (let attribute of restAttributes) {
            res.push({
                key: attribute.key,
                value: attribute.value,
                isReadonly: false,
                canBeDeleted: true,
                type: "text",
            });
        }

        return res;
    }

    private notifyHierarchyChanged() {
        const viewHierarchy = this._iv.getViewHierarchy();
        if (this._node.hierarchy_id === viewHierarchy?.getHierarchy()?.id) {
            viewHierarchy.reloadHierarchy();
        }
    }
}

import React, {Fragment, useMemo, useState} from "react";
import {Button, Card, Col, message, Modal, Popconfirm, Row, Spin, Table, Tag, Tooltip, Typography} from "antd";
import {HistoryOutlined} from "@ant-design/icons";
import {ViewHierarchy} from "./ViewHierarchy";
import HierarchiesService, {
    HierarchyLog,
    HierarchyLogOperation,
    HierarchyLogOperationNodeDump,
    HierarchyLogOperationNodeDumpRef,
    LogAuthor
} from "../../../services/HierarchiesService";
import {ManualPagedTable} from "../../Misc/Table/ManualPagedTable";
import {AsyncDataSource} from "../../Misc/Table/AsyncDataSourceGeneric";
import {ColumnsType} from "antd/es/table";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import moment from "moment/moment";
import {useFetchWithLoading} from "../../../hooks/useFetch";
import {TagPreview} from "../../ExploreResults";
import {TagReference} from "../../../services/ApiModels/TagReference";
import AllResultsService from "../../../services/AllResultsService";

export function HierarchyHistoryModal({viewHierarchy, hierarchy, visible, setVisible}: {
    viewHierarchy: ViewHierarchy,
    hierarchy: Hierarchy;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const dataSource = useMemo<AsyncDataSource<HierarchyLog>>(
        () => ({
            async values(page: number, limit: number): Promise<HierarchyLog[]> {
                const res = await HierarchiesService.getHierarchyLogsByCurrentUser(
                    hierarchy.id,
                    page,
                    limit
                );
                return res.data;
            }
        }),
        [hierarchy]
    );

    const close = () => {
        setVisible(false);
    };

    const columns: ColumnsType<HierarchyLog> = [
        {
            title: "Type",
            dataIndex: "type",
            render: (_, log) => (
                <LogTypeColumn log={log}/>
            )
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (createdBy: LogAuthor) => (
                <LogAuthorColumn author={createdBy}/>
            )
        },
        {
            title: "Reverted By",
            dataIndex: "reverted_by",
            render: (revertedBy?: LogAuthor) => (
                <LogAuthorColumn author={revertedBy}/>
            )
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (id, log) => {
                const revertIsDisabled = log.reverted_by != null;
                return (
                    <Popconfirm
                        disabled={revertIsDisabled}
                        title={"Are you sure to revert the log？"}
                        onConfirm={() => {
                            setIsLoading(true);
                            HierarchiesService.revertHierarchyLog(
                                hierarchy.id, id
                            ).then(
                                () => {
                                    viewHierarchy.reloadHierarchy(() => {
                                        message.success("Reverted");
                                    });
                                },
                            ).catch(
                                () => {
                                    message.error("Failed to revert");
                                }
                            ).finally(() => {
                                setIsLoading(false);
                            });
                        }}
                    >
                        <Typography.Link disabled={revertIsDisabled}>
                            Revert
                        </Typography.Link>
                    </Popconfirm>
                );
            }
        }
    ];

    const expandedRowRender = (log: HierarchyLog) => {
        return (
            <LogOperationsTable
                key={log.id}
                log={log}
                hierarchyId={hierarchy.id}
            />
        );
    };

    return (
        <Modal
            width={800}
            title={"Hierarchy Change History"}
            visible={visible}
            onCancel={close}
            modalRender={node => (
                <Spin spinning={isLoading}>
                    {node}
                </Spin>
            )}
            footer={[]}
        >
            <ManualPagedTable
                rowKey={(record: HierarchyLog) => record.id}
                asyncDataSource={dataSource}
                columns={columns}
                size={"small"}
                expandable={{expandedRowRender}}
            />
        </Modal>
    );
}

function LogTypeColumn({log}: { log: HierarchyLog }) {
    let data: {
        color: string | undefined,
        name: string
    };

    const type = log.type;

    if (type === "update-node") {
        data = {
            color: "blue",
            name: "Update Node"
        };
    } else if (type === "delete-node") {
        data = {
            color: "red",
            name: "Delete Node"
        };
    } else if (type === "new-node") {
        data = {
            color: "green",
            name: "New Node"
        };
    } else if (type === "bulk") {
        data = {
            color: "purple",
            name: "Bulk"
        };
    } else if (type === "add-multiple-node") {
        data = {
            color: "purple",
            name: "Add Multiple Nodes"
        };
    } else {
        data = {
            color: undefined,
            name: type
        };
    }

    return (
        <Tag color={data.color}>{data.name}</Tag>
    );
}

function LogAuthorColumn({author}: { author?: LogAuthor }) {
    if (author == null) {
        return <></>;
    } else {
        return (
            <Col>
                <div>
                    {author.user.name}
                </div>
                {moment.utc(author.date).local().format("MM/DD/YYYY, h:mm a")}
            </Col>
        );
    }
}

export function HierarchyHistoryButton({viewHierarchy}: { viewHierarchy: ViewHierarchy }) {
    return (
        <Button
            onClick={() => viewHierarchy.historyModalShow()}
            type={"link"}
            icon={<HistoryOutlined/>}
        />
    );
}


function LogOperationsTable({log, hierarchyId}: { hierarchyId: string, log: HierarchyLog }) {
    const [dataSource] = useState<AsyncDataSource<HierarchyLogOperation>>(() => ({
        async values(page: number, limit: number): Promise<HierarchyLogOperation[]> {
            const response = await HierarchiesService.getHierarchyLogOperations(
                hierarchyId, log.id, page, limit
            );
            return response.data;
        }
    }));

    const columns: ColumnsType<HierarchyLogOperation> = [
        {
            dataIndex: "id",
            title: "Node",
            render: (_, op) => {
                const node = op.prev ?? op.current;

                const getNodeColor = () => {
                    if (node?.label === "virtual") {
                        return "#e6c43f";
                    } else {
                        return "#3968d0";
                    }
                };

                return (
                    <>
                        <span style={{backgroundColor: getNodeColor()}} className="medium-circle"/>
                        {node?.text ?? "<unknown>"}
                    </>
                );
            }
        },
        {
            dataIndex: "type",
            title: "Type",
            render: (_, op) => (
                <OperationType operation={op}/>
            )
        }
    ];

    const expandedRowRender = (op: HierarchyLogOperation) => {
        if (op.type === "updated") {
            return (
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <NodeDumpPropertiesTable
                            hierarchyId={hierarchyId}
                            title={"Prev"}
                            node={op.prev!}
                        />
                    </Col>
                    <Col span={12}>
                        <NodeDumpPropertiesTable
                            hierarchyId={hierarchyId}
                            title={"Current"}
                            node={op.current!}
                        />
                    </Col>
                </Row>
            );
        } else if (op.type === "created") {
            return (
                <NodeDumpPropertiesTable
                    hierarchyId={hierarchyId}
                    title={"Current"}
                    node={op.current!}
                />
            );
        } else if (op.type === "deleted") {
            return (
                <NodeDumpPropertiesTable
                    hierarchyId={hierarchyId}
                    title={"Prev"}
                    node={op.prev!}
                />
            );
        } else {
            return (
                "not supported!"
            );
        }
    };

    return (
        <ManualPagedTable
            rowKey={(record: HierarchyLogOperation) => record.id}
            asyncDataSource={dataSource}
            columns={columns}
            size={"small"}
            expandable={{expandedRowRender}}
        />
    );
}

function OperationType({operation}: { operation: HierarchyLogOperation }) {
    let data: {
        color: string | undefined,
        name: string
    };

    const type = operation.type;

    if (type === "updated") {
        data = {
            color: "blue",
            name: "Updated"
        };
    } else if (type === "deleted") {
        data = {
            color: "red",
            name: "Deleted"
        };
    } else if (type === "created") {
        data = {
            color: "green",
            name: "New Node"
        };
    } else {
        data = {
            color: undefined,
            name: type
        };
    }

    return (
        <Tag color={data.color}>{data.name}</Tag>
    );
}

type NodeDumpProperty = {
    title: string;
    key: string;
    value: string | JSX.Element;
}

function NodeDumpPropertyRef(
    {node, reference}:
        { node: HierarchyLogOperationNodeDump, reference: HierarchyLogOperationNodeDumpRef }
) {
    const [tagReference, setTagReference] = useState<TagReference>();

    const isLoading = useFetchWithLoading(
        () => AllResultsService.getTagReferenceByNodeReference(node, reference),
        result => setTagReference(result.data),
        () => {
            message.error("Failed to load reference!");
        },
        [reference.id]
    );

    const preview = tagReference && tagReference.tag_number && (
        <TagPreview
            resultId={tagReference.result_reference.result_id}
            isFinalResult={tagReference.result_reference.is_final}
            tagNumber={tagReference.tag_number}
            tagId={tagReference.tag_id}
        />
    );

    let color = tagReference && tagReference.tag_number ? "#3968d0" : "#c40909";
    let tagNumber = tagReference?.tag_number ?? "failed to load";
    if (isLoading) {
        color = "#e6c43f";
        tagNumber = "loading...";
    }

    return (
        <>
            <Spin spinning={isLoading}>
                <Tooltip
                    placement="right"
                    title={preview}
                    color="white"
                    overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                    destroyTooltipOnHide={false}
                >
                    <a><span style={{backgroundColor: color}} className="medium-circle"/> {tagNumber}</a>
                </Tooltip>
            </Spin>
        </>
    );
}


function NodeDumpPropertyRefs({node}: { hierarchyId: string, node: HierarchyLogOperationNodeDump }) {
    return (
        <>
            {node.references.map(x => (
                <NodeDumpPropertyRef
                    key={x.id}
                    reference={x}
                    node={node}
                />
            ))}
        </>
    );
}

function NodeDumpPropertiesTable({title, node, hierarchyId}: {
    hierarchyId: string,
    title: string,
    node: HierarchyLogOperationNodeDump
}) {
    const data = useMemo<NodeDumpProperty[]>(() => {
        const result: NodeDumpProperty[] = [];
        result.push({
            title: "Class",
            key: "__label__",
            value: node.label
        });
        result.push({
            title: "Text",
            key: "__text__",
            value: node.text
        });
        result.push({
            title: "References",
            key: "__references__",
            value: (
                <NodeDumpPropertyRefs
                    node={node}
                    hierarchyId={hierarchyId}
                />
            )
        });
        for (let attribute of node.attributes) {
            result.push({
                title: attribute.key,
                key: attribute.key,
                value: attribute.value
            });
        }
        return result;
    }, [node]);

    const columns: ColumnsType<NodeDumpProperty> = [
        {
            dataIndex: "title",
            render: (title: string) => ({
                props: {
                    style: {background: "WhiteSmoke"}
                },
                children: title
            })
        },
        {
            dataIndex: "value"
        }
    ];

    return (
        <Card
            title={title}
            size="small"
            style={{
                height: "100%"
            }}
        >
            <Table
                id="object-attributes"
                dataSource={data}
                columns={columns}
                size="small"
                className={"ant-table"}
                style={{margin: 0}}
                pagination={false}
                showHeader={false}
                bordered={true}
            />
        </Card>
    );
}
